import { FC } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from 'styled-components';
import { Tip } from 'swingby-shared';
import { ContainerFlexSB } from '../../components/Container';
import { TextTitle } from '../../components/Typography';
import { DocumentType } from '../../apollo/types';

interface Props {
  posts: DocumentType<Tip>[];
}

const Articles: FC<Props> = ({ posts }) => {
  const router = useRouter();
  return (
    <>
      <TitleContainer>
        <TextTitle>
          방문전 필수 체크!
          <br />
          스포츠몬스터 꿀팁
        </TextTitle>
      </TitleContainer>
      <GridContainer>
        {posts?.map((el) => {
          return (
            <Image
              key={el.id}
              unoptimized
              src={el.thumbnail}
              width="288"
              height="190"
              alt="post"
              onClick={() => router.push(`/main/tips/${el.id}`)}
            />
          );
        })}
      </GridContainer>
    </>
  );
};

export default Articles;

const TitleContainer = styled(ContainerFlexSB)`
  margin-bottom: 14px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  cursor: pointer;
  :nth-child(1) {
    animation: 0.2s ease-out 0s both smoothAppearX;
  }
  :nth-child(2) {
    animation: 0.2s ease-out 0.04s both smoothAppearX;
  }
  :nth-child(3) {
    animation: 0.2s ease-out 0.08s both smoothAppearX;
  }
  :nth-child(4) {
    animation: 0.2s ease-out 0.12s both smoothAppearX;
  }
`;
