import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { T12S2, T12P2 } from '../T';

export const Footer: React.FC = () => {
  const router = useRouter();
  return (
    <Content>
      <strong>스포츠몬스터 대전신세계 아트앤사이언스점</strong>
      <br />
      대전광역시 유성구 엑스포로1 , 6층 & 7층
      <br />
      <br />
      <Term onClick={() => router.push('/terms/service')}>서비스 이용약관</Term> &nbsp;|&nbsp;&nbsp;
      <Term onClick={() => router.push('/terms/privacy')}>개인정보처리방침</Term> &nbsp;|&nbsp;&nbsp;
      <Term onClick={() => router.push('/terms/park')}>파크 이용약관</Term>
      <br />
      <br />
      상호: 위피크(주) 스포츠몬스터 대전신세계
      <br />
      주소: 대전광역시 유성구 엑스포로1 , 6층 & 7층
      <br />
      대표이사: 김정준
      <br />
      사업자등록번호: 214-87-97600
      <br />
      통신판매업신고: 제2016-경기하남-0426호
      <br />
      특허출원번호: 10-2017-0115016
      <br />
      고객센터 및 단체문의: 1668-4832(유료)
      <br />
      상담시간: 평일 오전 9시~ 오후 6시(점심 12-1시 제외)
      <br />
      이메일주소: sportsmonster@wepeak.co.kr
    </Content>
  );
};

const Content = styled(T12S2)({
  marginTop: '-16px',
  height: 260,
});

const Term = styled(T12P2)({
  cursor: 'pointer',
  opacity: '0.7',
});
