import React, { Dispatch, FC, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import { SetterOrUpdater } from 'recoil';
import { styled } from '@mui/system';
import { Box, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalSession: SetterOrUpdater<boolean>;
}

export const WelcomeCouponModal: FC<Props> = ({ isModalOpen, setModalOpen, setModalSession }) => {
  const router = useRouter();
  const handleClose = () => setModalOpen(false);
  const handleSessionClose = () => {
    setModalSession(false);
    setModalOpen(false);
  };

  return (
    <ModalRoot
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <Box sx={{ display: 'flex', gap: 2 }} onClick={() => router.push('/ticket')}>
          <AmountBox>5,000</AmountBox>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1 }}>
            <Box sx={{ fontSize: 16, fontWeight: 'bold' }}>
              첫 구매라면? <span style={{ color: '#336BFE' }}>5,000</span>원 할인!
            </Box>
            <Box sx={{ mt: 0.2, color: '#7F8D9F' }}>스포츠몬스터 대전점 고객 대상</Box>
          </Box>
        </Box>
        <ButtonArea onClick={handleSessionClose}>
          <IconButton size="small" aria-label="close">
            <CloseIcon />
          </IconButton>
        </ButtonArea>
      </ModalBox>
    </ModalRoot>
  );
};

const ModalRoot = styled(Snackbar)`
  max-width: 640px;
  @media only screen and (max-width: 640px) {
    left: 16px;
    right: 16px;
    bottom: 70px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1199px) {
    width: 100%;
    left: calc(50% - 320px);
    bottom: 54px;
    padding: 16px;
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    left: calc(50% - 45px);
    bottom: 54px;
    padding: 16px;
  }
`;

const ModalBox = styled(Box)`
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 20px #3b4a7666;
  padding: 12px;
  color: #1f273f;
`;

const AmountBox = styled('div')`
  width: 54px;
  height: 54px;
  background: #1f273f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
`;

const ButtonArea = styled('div')`
  position: absolute;
  top: 4px;
  right: 4px;
`;
