import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { styled } from '@mui/system';
import { Box, Typography, Modal, Button } from '@mui/material';
import { MaintenanceModalState } from '../../atoms';
import maintenanceSchedule from '../../lib/maintenanceSchedule';
import SpeakerIcon from '../../public/twemoji_loudspeaker.svg';

// Timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export const MaintenanceModal: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [sessionModalState, setSessionModalState] = useRecoilState(MaintenanceModalState);
  const currentTime = dayjs().tz('Asia/Seoul');
  const { maintenanceStart, maintenanceEnd } = maintenanceSchedule;

  const handleClose = () => {
    setSessionModalState(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const formatCheck = !!maintenanceStart && !!maintenanceEnd;
    const expireCheck =
      !!maintenanceEnd && currentTime.isBefore(dayjs(maintenanceEnd, 'YYYY-MM-DD HH:mm').tz('Asia/Seoul'));

    if (!formatCheck || !expireCheck) {
      setIsModalOpen(false);
    }
  }, [currentTime, maintenanceStart, maintenanceEnd]);

  return (
    <Modal
      open={isModalOpen && sessionModalState}
      onClose={handleClose}
      disableScrollLock={true}
      aria-labelledby="maintenance-modal-title"
      aria-describedby="maintenance-modal-description"
    >
      <ModalBox>
        <HeadArea>
          <Image src={SpeakerIcon} width={52} height={52} alt="speaker_icon" />
          <Typography variant="h6" component="h3" sx={{ ml: 2, fontSize: 16, color: '#1F273F' }}>
            알려드립니다!
          </Typography>
        </HeadArea>
        <Typography
          id="maintenance-modal-title"
          variant="h4"
          component="h2"
          sx={{ mt: 5, fontSize: 32, fontWeight: 'bold', color: '#1F273F' }}
        >
          서비스 점검 안내
        </Typography>
        <Typography id="maintenance-modal-description" sx={{ mt: 3, color: '#7F8D9F', fontSize: 16 }}>
          잠시 후{' '}
          <span style={{ color: '#336BFE', fontWeight: 'bold' }}>
            {dayjs(maintenanceStart, 'YYYY-MM-DD HH:mm').tz('Asia/Seoul').format('M월 D일 HH:mm')} 부터{' '}
            {dayjs(maintenanceEnd, 'YYYY-MM-DD HH:mm').tz('Asia/Seoul').format('M월 D일 HH:mm')} 까지
          </span>{' '}
          서비스 점검이 예정되어 있습니다. 해당 시간 동안 스포츠몬스터 앱 및 웹사이트 접속이 불가능하오니 이용에 참고
          부탁드립니다.
          <br />
          <br />
          감사합니다.
        </Typography>
        <ButtonArea>
          <ModalButton variant={'contained'} disableElevation={true} onClick={handleClose} size={'large'}>
            확인
          </ModalButton>
        </ButtonArea>
      </ModalBox>
    </Modal>
  );
};

const ModalBox = styled(Box)`
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 2rem 2rem 0 0;
  padding: 32px;
  max-width: 640px;
  @media only screen and (max-width: 640px) {
    left: 0;
    right: 0;
  }
  @media only screen and (min-width: 641px) and (max-width: 1199px) {
    width: 100%;
    left: calc(50% - 320px);
    padding: 16px;
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    left: calc(50% - 45px);
    padding: 16px;
  }
`;

const HeadArea = styled(Box)`
  display: flex;
  align-items: center;
`;

const ButtonArea = styled(Box)`
  margin-top: 7rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

const ModalButton = styled(Button)`
  width: 100%;
  background-color: #1f273f;
  border-radius: 16px;
  font-size: 16px;
`;
