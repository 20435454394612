import React, { useState, useRef, useEffect, ReactNode, FC, memo } from 'react';
import Image from 'next/image';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import { sampleSize } from 'lodash';
import { ContainerFlexSB } from '../Container';
import { useRouter } from 'next/router';
import { Event, Attraction as IAttraction } from 'swingby-shared';
import { TextTitle } from '../Typography';
import { BlueButton } from '../Button';
import { DocumentType } from '../../apollo/types';

interface Props {
  events: DocumentType<Event>[];
}

export const MainBanner: FC<Props> = ({ events }) => {
  const router = useRouter();
  const slider = useRef<any>(null);

  let SETTINGS: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <section className="main-carousel">
      <Slider ref={slider} {...SETTINGS}>
        {events.map(({ id, thumbnail }) => (
          <ImageContainer key={id} onClick={() => router.push(`/main/event/${id}`)}>
            <Image unoptimized src={thumbnail} alt="event_image" width="640" height="360" />
          </ImageContainer>
        ))}
      </Slider>
    </section>
  );
};

const ImageContainer = styled.div`
  position: relative;
  background-color: #fff;
  cursor: pointer;
  img {
    object-fit: cover;
  }
  .slick-dots {
    position: absolute;
    bottom: 0;
    width: 50px !important;
  }
`;

export const AttractionSlider = ({ carousel }: { carousel: IAttraction[] }) => {
  const router = useRouter();
  const [height, setHeight] = useState<number>(0);
  const containerRef = useRef<any>(null);

  const handleResize = () => {
    const width = containerRef.current.scrollWidth;
    const height = Math.round((width - 40) * 0.75);
    setHeight(height);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AttractionContainer ref={containerRef}>
      <TitleContainer>
        <TextTitle color="#fff" weight="bold" size="14px">
          이런 어트랙션은 어떠세요?
        </TextTitle>
      </TitleContainer>
      <AttractionItemContainer height={height}>
        {sampleSize(carousel, 4).map((attraction, idx) => {
          const { uid, theme, name, imageUrl } = attraction;
          return (
            <AttractionItem
              key={uid}
              style={{ animation: `0.5s ease-out ${idx * 0.04}s both smoothAppearY` }}
              onClick={() => router.push(`main/attraction/${uid}`)}
              thumbnail={imageUrl}
            >
              <div className="thumbnail" />
              <div className="mask" />
              <div className="title">{name}</div>
            </AttractionItem>
          );
        })}
      </AttractionItemContainer>
      <BlueButton
        style={{
          color: '#fff',
          backgroundColor: 'transparent',
          width: '100%',
          marginTop: '20px',
          border: '2px solid #99dcfb88',
        }}
        onClick={() => router.push('/main/attraction')}
      >
        전체보기
      </BlueButton>
    </AttractionContainer>
  );
};

export const MemoizedAttractionSlider = memo(AttractionSlider);

const AttractionContainer = styled.div`
  margin: 20px 0 0 0;
  padding-top: 20px;
  border-top: dashed 2px #99dcfb;
  margin-bottom: 80px;
`;

const TitleContainer = styled(ContainerFlexSB)`
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
`;

const AttractionItemContainer = styled.div<{ height?: number }>`
  display: grid;
  gap: 16px;
  height: ${({ height }) => height}px;
  grid-template-columns: 1fr 1fr;
  place-content: stretch;
`;

const AttractionItem = styled.div<{ thumbnail?: string }>`
  cursor: pointer;
  color: #fff;
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(#000000aa, #00000000);
    border-radius: 12px 12px 0 0;
  }
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center / cover no-repeat url(${({ thumbnail }) => thumbnail}), #ffffff66;
    border-radius: 12px;
  }
  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    font-family: NEXON Lv1 Gothic;
    font-size: 12px;
    font-weight: bold;
    white-space: wrap;
    text-align: left;
    line-height: 1.2em;
  }
`;

export const Carousel = ({ children }: { children: ReactNode }) => {
  const scrollRef = useRef<any>(null);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);

  const onDragStart = (e: any) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e: any) => {
    if (isDrag) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;

      scrollRef.current.scrollLeft = startX - e.pageX;

      if (scrollLeft === 0) {
        setStartX(e.pageX);
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        setStartX(e.pageX + scrollLeft);
      }
    }
  };
  return (
    <CarouselContainer
      ref={scrollRef}
      onMouseDown={onDragStart}
      onMouseMove={onDragMove}
      onMouseUp={onDragEnd}
      onMouseLeave={onDragEnd}
    >
      {children}
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
