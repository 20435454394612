import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Color } from '../../constants';

interface Props {
  expires: number;
  noTicket: boolean;
}

const MyTicket = ({ expires, noTicket }: Props) => {
  const router = useRouter();
  const Dday = Math.round(expires);

  if (Dday < 0 || noTicket)
    return (
      <Wrapper>
        <Text style={{ color: Color.darkBlue }}>온라인으로 예매하고 바로 입장!</Text>
        <Link onClick={() => router.push('/ticket')}>예매하기</Link>
      </Wrapper>
    );

  return (
    <Wrapper>
      <div>
        {Dday == 0 ? (
          <Text style={{ color: Color.green }}>예약 당일입니다. 파크에서 뵐게요!</Text>
        ) : (
          <Text style={{ fontSize: 16 }}>
            내 예약일까지
            <Text style={{ color: Color.alert2, fontSize: 16, paddingLeft: 10 }}>D-{Dday}</Text>
          </Text>
        )}
      </div>
      <Link onClick={() => router.push('/ticketbox')}>티켓확인</Link>
    </Wrapper>
  );
};

export default MyTicket;

const Wrapper = styled.section`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  border-radius: 30px;
  border: 2px dashed #0fd6f7;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;

const Link = styled.span`
  font-size: 14px;
  border-left: 1px solid #0fd6f755;
  padding-left: 16px;
  margin-left: 16px;
  cursor: pointer;
  text-align: center;
  color: ${Color.ticketBlue};
`;
