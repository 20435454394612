import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from 'styled-components';
import { TextTitle } from '../Typography';
import { ContainerFlexSB } from '.';
import { Color } from '../../constants';
import { Attraction } from 'swingby-shared';
import see_all from '../../public/see-all-rounded.svg';

interface Props {
  attractions: Attraction[];
}

const AttractionContainer: FC<Props> = ({ attractions }) => {
  const router = useRouter();
  return (
    <Container>
      <TitleContainer>
        <TextTitle>
          대전신세계점이 추천하는
          <br />
          BEST 어트랙션!
        </TextTitle>
      </TitleContainer>
      <ItemContainer>
        {attractions.map((attraction, idx) => {
          return (
            <AttractionItem
              key={attraction.uid}
              style={{ animation: `0.5s ease-out ${idx * 0.04}s both smoothAppearY` }}
              onClick={() => router.push(`main/attraction/${attraction.uid}`)}
            >
              <Image unoptimized src={attraction.thumbnail} alt="attraction_image" width="126" height="126" />
              <div>{attraction.name}</div>
            </AttractionItem>
          );
        })}
        <AttractionItem
          style={{ opacity: 0, animation: `0.5s ease-out 0.32s both smoothAppearY` }}
          onClick={() => router.push('/main/attraction')}
        >
          <Image src={see_all} alt="attraction_image" width="126" height="126" />
          <div>전체보기</div>
        </AttractionItem>
      </ItemContainer>
    </Container>
  );
};

export default AttractionContainer;

const TitleContainer = styled(ContainerFlexSB)`
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 24px;
`;

const Container = styled.div`
  background-color: ${Color.background};
  padding: 24px 0 12px 0;
  margin: 0 0 12px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 18px;
`;

const AttractionItem = styled.div`
  padding: 0 calc(100% / 4 * 0.08);
  margin: 0 0 18px 0;
  width: 25%;
  cursor: pointer;
  text-align: center;
  color: ${Color.secondary1};
  img {
    width: 100%;
    border-radius: 50%;
    margin: 0 0 8px 0;
  }
  div {
    font-size: 12px;
    line-height: 12px;
    white-space: break-spaces;
    overflow-y: hidden;
  }
`;
