import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { isLoggedInVar } from '../../apollo/cache';
import { QUERY_ORDERLIST } from '../../apollo/queries';
import type { Order, UserProduct } from 'swingby-shared';
import type { IResult } from '../../apollo/types';

export const useOrderList = () => {
  const isLoggedIn = isLoggedInVar();

  const { data: orders, loading } = useQuery<IResult<Order[]>>(QUERY_ORDERLIST, {
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network',
    variables: { filter: { _operators: { status: { in: ['paid', 'cancelled'] } } } },
  });

  // 패스 구매 내역
  const passList = orders?.result.filter(
    ({ userTickets, userCoupons }) =>
      userTickets.length === 0 && userCoupons.length !== 0 && userCoupons[0].coupon.type === 'membership',
  );

  // 사용 가능한 시즌패스
  const usablePass = passList?.filter(({ userCoupons }) => userCoupons[0].status === 'paid');

  // 모든 구매 내역
  const orderList = orders?.result
    .filter(
      ({ userCoupons, userTickets, userProducts }) =>
        userCoupons.length === 0 && (userTickets.length !== 0 || userProducts.length !== 0),
    )
    .sort((a, b) => {
      if (!a.userTickets && !b.userTickets) return 0; //티켓 포함하지 않는 경우 정렬 바꾸지 않음
      if (!a.userTickets) return 1;
      if (!b.userTickets) return -1;
      return new Date(a.userTickets[0].reservedAt).getTime() < new Date(b.userTickets[0].reservedAt).getTime() ? -1 : 1;
    });

  // 사용 예정 구매 내역
  const usableOrders = orderList?.filter((order) => {
    const allItemsInvalid =
      order.userTickets.every((ut) => ut.status !== 'paid') &&
      order.userProducts.every((up) => {
        const isPackage = (item: any): item is UserProduct => Boolean(item?.product?.metaData?.packageOriginalPrice);
        const allPackageItemsUsed =
          order.userProducts
            .filter((userProduct) => userProduct.metaData.parentPackage === up.id)
            .every((filteredUserProduct) => filteredUserProduct.status === 'used') &&
          order.userTickets
            .filter((userTicket) => userTicket.metaData.parentPackage === up.id)
            .every((filteredUserTicket) => filteredUserTicket.status === 'used');

        return isPackage(up) ? allPackageItemsUsed : up.status !== 'paid';
      });
    const isReservationDateValid = order.userTickets.length
      ? dayjs(order.userTickets[0].reservedAt).diff(dayjs(), 'day') >= 0
      : true;
    return order.status === 'paid' && isReservationDateValid && !allItemsInvalid;
  });

  return { loadingOrders: loading, passList, usablePass, orderList, usableOrders };
};
