import React, { useState, useEffect, useCallback } from 'react';
import Image from 'next/image';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import styled from 'styled-components';
import { Holiday as IHoliday } from 'swingby-shared';
import { Color } from '../../constants';
import notice from '../../public/icon-notice-red.svg';

dayjs.locale('ko');

const Ticker = ({ holidays = [] }: { holidays: IHoliday[] }) => {
  const [innerDivWidth, setInnerDivWidth] = useState(0);
  const [parentDivWidth, setParentDivWidth] = useState(0);
  const [marquee, setMarquee] = useState<boolean>(false);

  const measureRef = useCallback((node) => {
    if (node !== null) {
      setInnerDivWidth(node.scrollWidth);
      setParentDivWidth(node.parentElement.clientWidth);
    }
  }, []);

  useEffect(() => {
    innerDivWidth > parentDivWidth ? setMarquee(true) : setMarquee(false);
  }, [innerDivWidth, parentDivWidth]);

  const notiFormatter = (holidays: IHoliday[]) => {
    const dateFormatter = (date: Date) => dayjs(date).format('MM/DD일(ddd)');

    if (holidays.length === 1 && holidays[0].memo) {
      return (
        <>
          <strong>
            {dateFormatter(holidays[0].holiday)}은 {holidays[0].memo}
          </strong>
          에 의한 <strong>휴장</strong>입니다
        </>
      );
    }
    if (holidays.length === 1) return <strong>{dateFormatter(holidays[0].holiday)}은 휴장입니다</strong>;

    const arr = holidays?.map(({ holiday, memo }) => {
      if (!memo) {
        return dateFormatter(holiday);
      }

      return dateFormatter(holiday) + ' ' + memo;
    });

    return (
      <>
        <strong>{arr?.join()}</strong>에 의한 <strong>휴장</strong>입니다
      </>
    );
  };
  return (
    <>
      {!!holidays.length && (
        <ClosedNoticeWrapper>
          <ClosedNotice
            ref={measureRef}
            className={marquee ? 'marquee' : ''}
            offset={parentDivWidth}
            width={innerDivWidth}
          >
            <Image src={notice} width={16} height={16} alt="notice" className="notice" />
            {notiFormatter(holidays as IHoliday[])}
          </ClosedNotice>
        </ClosedNoticeWrapper>
      )}
    </>
  );
};

export default Ticker;

const ClosedNotice = styled.div<{ offset: number; width: number }>`
  white-space: nowrap;
  color: ${Color.primary1};
  font-family: NEXON Lv1 Gothic;
  font-size: 1.15em;
  height: 40px;
  line-height: 1.5em;
  text-align: center;

  strong {
    margin-left: 3px;
  }

  .notice {
    top: 3px !important;
  }

  &.marquee {
    animation: ${({ offset, width }) => ((10 * offset) / width).toFixed(2)}s linear 0s infinite both marquee;
  }
  @keyframes marquee {
    from {
      transform: ${({ offset }) => `translateX(${offset}px)`};
    }
    to {
      transform: ${({ width }) => `translateX(-${width}px)`};
    }
  }
`;

const ClosedNoticeWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
