import React, { useEffect, useState } from 'react';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { sampleSize } from 'lodash';
import { useRecoilState } from 'recoil';
import { Skeleton, Grid } from '@mui/material';
import { Container, ContainerWrapper } from '../components/Container';
import { GNB } from '../components/GNB';
import { MainBanner } from '../components/CarouselBanner';
import MyTicket from './main/MyTicket';
import Articles from './main/Articles';
import { Footer } from '../components/Footer';
import BottomNav from '../components/GNB/BottomNav';
import { GET_ATTRACTIONS, QUERY_TIPS, QUERY_EVENTLIST, QUERY_USERCOUPON, QUERY_NEXT_HOLIDAY } from '../apollo/queries';
import { IPaginate, IResult, DocumentType } from '../apollo/types';
import useTempInfo from '../hooks/useTempInfo';
import { Attraction, Tip, Event, UserCoupon, Holiday } from 'swingby-shared';
import Ticker from './main/Ticker';
import { isLoggedInVar, userVar } from '../apollo/cache';
import AttractionContainer from '../components/Container/AttractionContainer';
import { client, useApolloNetworkStatus } from '../apollo';
import UserCouponModal from '../components/Modal/UserCouponModal';
import { welcomeCouponModalState } from '../atoms';
import { WelcomeCouponModal } from '../components/Modal/WelcomeCouponModal';
import { MaintenanceModal } from '../components/Modal/MaintenanceModal';
import { useOrderList } from '../operations/queries/getOrderList';

// Timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Home({ attractions }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { removeTempInfo } = useTempInfo();
  const { numPendingQueries: loading } = useApolloNetworkStatus();
  const isLoggedIn = isLoggedInVar();
  const user = useReactiveVar(userVar);
  const [show, setShow] = useState<boolean>(false);
  const [sessionModalState, setSessionModalState] = useRecoilState(welcomeCouponModalState);
  const [showWelcomeCouponModal, setShowWelcomeCouponModal] = useState<boolean>(false);
  const checkDate = dayjs.tz(new Date(), 'Asia/Seoul').diff(dayjs.tz('2022-03-01', 'Asia/Seoul'));
  const couponName = checkDate < 0 ? '입장권 10,000원 할인 쿠폰' : '첫 구매 할인쿠폰';

  const { usableOrders } = useOrderList();
  const expires = dayjs(usableOrders?.[0]?.userTickets[0].reservedAt).diff(dayjs(), 'day', true);

  const { data: events } = useQuery<IResult<IPaginate<DocumentType<Event>>>>(QUERY_EVENTLIST);
  const { data: posts } = useQuery<IResult<IPaginate<DocumentType<Tip>>>>(QUERY_TIPS);
  const { data: holidays } = useQuery<IResult<Holiday[]>>(QUERY_NEXT_HOLIDAY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        _operators: {
          holiday: { gte: dayjs().startOf('day').toDate(), lt: dayjs().add(14, 'day').startOf('day').toDate() },
        },
      },
    },
  });

  useEffect(() => {
    removeTempInfo();
    if (!isLoggedIn && sessionModalState) {
      setShowWelcomeCouponModal(true);
    }
  }, []);

  const [checkForNewUserGift, { data: userCoupon }] = useLazyQuery<IResult<UserCoupon>>(QUERY_USERCOUPON, {
    onCompleted() {
      setShow(true);
    },
  });

  const prevPath = globalThis?.sessionStorage?.getItem('prevPath');

  useEffect(() => {
    if (user && prevPath?.startsWith('/signup')) {
      checkForNewUserGift({ variables: { filter: { owner: user.id, metaData: { phoneNumber: user.cellPhone } } } });
    }
  }, [prevPath]);

  if (loading) {
    return (
      <>
        <GNB />
        <section style={{ height: '100%' }}>
          <Skeleton variant="rectangular" animation="wave" sx={{ mb: 4, pt: '56%' }} />
          <section style={{ padding: '0 24px' }}>
            <Skeleton variant="rectangular" animation="wave" height={60} sx={{ borderRadius: 30, mb: 3 }} />
            {/* 어트랙션 */}
            <Skeleton variant="text" animation="wave" width={168} height={30} />
            <Skeleton variant="text" animation="wave" width={108} height={30} />
            <Grid container columnSpacing={2} sx={{ mt: 1, mb: 6 }}>
              {Array.from({ length: 8 }).map((_, idx) => (
                <Grid item key={idx} xs={3} sx={{ mb: 1 }}>
                  <Skeleton variant="circular" animation="wave" width="100%" sx={{ pt: '100%' }} />
                  <Skeleton variant="text" animation="wave" width="75%" sx={{ margin: '0 auto' }} />
                </Grid>
              ))}
            </Grid>
            {/* 꿀팁 */}
            <Skeleton variant="text" animation="wave" width={118} height={30} />
            <Skeleton variant="text" animation="wave" width={124} height={30} sx={{ mb: 2 }} />

            <Grid container sx={{ mb: 4 }}>
              {Array.from({ length: 4 }).map((_, idx) => (
                <Grid item key={idx} xs={6}>
                  <Skeleton variant="rectangular" animation="wave" sx={{ margin: '4px', pt: '60%' }} />
                </Grid>
              ))}
            </Grid>
            <Footer />
          </section>
        </section>
        <BottomNav />
      </>
    );
  }

  return (
    <>
      <GNB />
      <section style={{ height: '100%' }}>
        <div style={{ backgroundColor: '#ffffff' }}>
          <MainBanner events={events?.result.items || []} />
        </div>
        <div style={{ backgroundColor: '#fff', padding: '30px 24px 6px' }}>
          <Ticker holidays={holidays?.result || []} />
          <MyTicket expires={expires} noTicket={!usableOrders?.length} />
        </div>
        <AttractionContainer attractions={attractions || []} />
        <ContainerWrapper>
          <Articles posts={posts?.result.items || []} />
        </ContainerWrapper>
        <Container>
          <Footer />
        </Container>
      </section>
      <BottomNav />
      <MaintenanceModal />
      <UserCouponModal
        isModalOpen={show}
        setModalOpen={setShow}
        userCoupon={userCoupon?.result}
        couponName={couponName}
      />
      {checkDate > 0 && (
        <WelcomeCouponModal
          isModalOpen={showWelcomeCouponModal}
          setModalOpen={setShowWelcomeCouponModal}
          setModalSession={setSessionModalState}
        />
      )}
    </>
  );
}

export const getStaticProps: GetStaticProps<{ attractions: Attraction[] }> = async () => {
  const { data } = await client.query<IResult<Attraction[]>>({ query: GET_ATTRACTIONS });
  const attractions = sampleSize(data.result, 7);
  return {
    props: {
      attractions,
    },
  };
};
