const maintenanceStart = process.env.NEXT_PUBLIC_MAINTENANCE_START || '';
const maintenanceEnd = process.env.NEXT_PUBLIC_MAINTENANCE_END || '';
const formatRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/;

const checkDateFormat = (dateToCheck: string) => formatRegex.test(dateToCheck);

const maintenanceSchedule = {
  maintenanceStart: checkDateFormat(maintenanceStart) ? maintenanceStart : '',
  maintenanceEnd: checkDateFormat(maintenanceEnd) ? maintenanceEnd : '',
};

export default maintenanceSchedule;
