import React, { Dispatch, FC, SetStateAction } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Modal } from 'react-bootstrap';
import { BlueButton } from '../Button';
import { UserCoupon } from 'swingby-shared';
import success from '../../public/success.svg';
import gift from '../../public/wrapped-gift.png';

interface Props {
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  userCoupon?: UserCoupon;
  couponName: string;
}

const UserCouponModal: FC<Props> = ({ isModalOpen, setModalOpen, userCoupon, couponName }) => {
  const router = useRouter();
  const handleClose = () => setModalOpen(false);

  return (
    <Modal centered backdrop="static" show={isModalOpen} onHide={handleClose}>
      <div className="modal-container">
        <div className="modal-status">
          <Image unoptimized src={userCoupon ? gift : success} alt="modal_image" width="80" height="80" />
        </div>
        <div className="modal-content-title">{userCoupon ? '회원가입을 축하해요!' : '이전에 쿠폰을 받으셨네요'}</div>
        <div className="modal-content-text">
          {userCoupon ? `[${couponName}]이 지급되었어요.` : `[${couponName}]은 최초 가입 시에만 받을 수 있어요.`}
        </div>
        <div className="modal-menu">
          <BlueButton
            onClick={() => {
              if (userCoupon) return router.push('/users/coupons');
              else setModalOpen(false);
            }}
          >
            {userCoupon ? '쿠폰함으로 이동' : '닫기'}
          </BlueButton>
        </div>
      </div>
    </Modal>
  );
};

export default UserCouponModal;
